import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { API_BASE_URL, API_ENDPOINTS } from '../api.js';
import '../components/CreatePost.css';
import axios from 'axios';
import Header from "../header.js";
import FileDeleteComponent from "../components/FileDelete.js";
import FileScroller from "../components/FileList.js";
import QuoteForm from "../components/quoteUpload.js";

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
    ],
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'align',
    'link', 'image'
]

export default function CreatePost() {
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState('');
    const [genre, setGenre] = useState('');
    const [author, setAuthor] = useState('');
    const [questions, setQuestions] = useState([]);
    const [video, setVideo] = useState('');
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.FETCH_QUESTIONS}`);
                setQuestions(response.data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, []);
    async function createNewPost(ev) {
        ev.preventDefault();
        const data = new FormData();
        data.set('title', title);
        data.set('summary', summary);
        data.set('content', content);
        data.set('file', file[0]);
        data.set('genre', genre);
        data.set('author', author);
        data.set('video', video)
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.POST}`, {
            method: 'POST',
            body: data,
        });
        if (response.status !== 200) {
            alert('Method Failed');
        } else {
            alert('Book added successfully');
            
        }
    }

    if (localStorage.getItem('username')) {
        if (localStorage.getItem('username') !== "admin") {
            return <Navigate to="/" />;
        }
    } else {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <Header/>
        <div className="create-post-page">
        <div className="create-post-container">
            <h1>Create New Post</h1>
            <form onSubmit={createNewPost}>
                <div className="form-group">
                    <input type="text" placeholder="Title" value={title} onChange={ev => setTitle(ev.target.value)} />
                </div>
                <div className="form-group">
                    <textarea placeholder="Short Summary" value={summary} onChange={ev => setSummary(ev.target.value)} />
                </div>
                <div className="form-group">
                    <input type="text" placeholder="Author" value={author} onChange={ev => setAuthor(ev.target.value)} />
                </div>
                <div className="form-group">
                    <input type="text" placeholder="Video hash" value={video} onChange={ev => setVideo(ev.target.value)} />
                </div>
                <div className="form-group">
                    <select className="genre" value={genre} onChange={ev => setGenre(ev.target.value)}>
                        <option value="" disabled hidden>Select Genre</option>
                        <option value="Crime/Mystery">Crime/Mystery</option>
                        <option value="Value Adding">Value Adding</option>
                        <option value="Drama">Drama</option>
                        <option value="History">History</option>
                        <option value="Science Fiction">Science</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Biography">Biography</option>
                        <option value="Romance">Romance</option>
                    </select>
                </div>
                <div className="form-group file-input">
                    <label htmlFor="file-upload" className="custom-file-upload">
                        Choose Cover Image
                    </label>
                    <input id="file-upload" type="file" onChange={ev => setFile(ev.target.files)} />
                </div>
                <div className="form-group quill-editor">
                    <ReactQuill
                        value={content}
                        onChange={newContent => setContent(newContent)}
                        modules={modules}
                        formats={formats}
                    />
                </div>
                <button type="submit" className="submit-btn">Upload Summary</button>
            </form>
        </div>
        <div className="questions-container">
                <h2>Recent Questions</h2>
                {questions.map((question, index) => (
                    <div key={index} className="question-item">
                        <div className="question-username">{question.username}</div>
                        <div>{question.question}</div>
                    </div>
                ))}
            </div>
        </div>
            <QuoteForm/>
                <FileDeleteComponent/>
                <FileScroller/>
        </div>  );
}