import React from 'react';
import { Typography, Box, Container, Grid, Paper } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { ThemeContext } from '../theme';
import aboutImage from '../images/book.jpg'; // Replace with your image path

const AboutPage = () => {
  // const theme = useTheme();
  const { isDarkMode } = React.useContext(ThemeContext);
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const MotionPaper = motion(Paper);

  return (
    <Container maxWidth="lg" sx={{ my: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <motion.img 
            src={aboutImage} 
            alt="About ReadQuest"
            style={{ 
              width: '1000%', 
              borderRadius: '8px', 
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Typography variant="h2" component="h1" gutterBottom sx={{ 
              fontWeight: 'bold', 
              color: isDarkMode ? 'white' : 'black',
              textShadow: isDarkMode ? '2px 2px 4px rgba(255,255,255,0.1)' : '2px 2px 4px rgba(0,0,0,0.1)'
            }}>
              Story
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: isDarkMode ? 'white' : 'black' }}>
              As someone who has always struggled to finish books, I often found myself missing out on valuable insights. 
              This inspired me to create ReadQuest—a platform designed for quick learners like myself!
            </Typography>
          </motion.div>
        </Grid>
      </Grid>

      <Box sx={{ mt: 8 }}>
        <Grid container spacing={4}>
          {[
            { title: "Why ReadQuest?", content: "I wanted to share the essence of great books in smaller, digestible segments, making it easier for anyone to grasp important concepts without feeling overwhelmed." },
            { title: "Features You'll Love", content: "Audio Reader, Interactive Definitions, Quest Coins" },
            { title: "Vision", content: "This project started as a hobby, driven by my desire to read and share amazing books one at a time. We aim to gradually feature many more books!" },
          ].map((section, index) => (
            <Grid item xs={12} md={4} key={index}>
              <MotionPaper
                elevation={3}
                sx={{
                  p: 3,
                  height: '100%',
                  backgroundColor: isDarkMode ? 'rgba(255,255,255,0.05)' : 'white',
                  color: isDarkMode ? 'white' : 'black',
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {section.title}
                </Typography>
                <Typography variant="body2">
                  {section.content}
                </Typography>
              </MotionPaper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Typography variant="body1" sx={{ color: isDarkMode ? 'white' : 'black', fontStyle: 'italic' }}>
            "I look forward to hearing your thoughts and suggestions as we embark on this learning quest together! 🌟"
          </Typography>
        </motion.div>
      </Box>
    </Container>
  );
};

export default AboutPage;