import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../api.js' 

const QuoteForm = () => {
  const [formData, setFormData] = useState({
    quote: '',
    explaination: '',
    book: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${API_BASE_URL}${API_ENDPOINTS.UQUOTE}`, formData);
      setSubmitSuccess(true);
      setFormData({ quote: '', explaination: '', book: '' });
    } catch (error) {
    //   console.error('Error submitting quote:', error);
      alert(error)
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="quote"
        value={formData.quote}
        onChange={handleChange}
        placeholder="Enter quote"
        required
      />
      <textarea
        name="explaination"
        value={formData.explaination}
        onChange={handleChange}
        placeholder="Enter explanation"
        required
      />
      <input
        type="text"
        name="book"
        value={formData.book}
        onChange={handleChange}
        placeholder="Enter book name"
        required
      />
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit Quote'}
      </button>
      {submitSuccess && alert('Quote submitted successfully!')}
    </form>
  );
};

export default QuoteForm;
