import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const ScrollProgressIndicator = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = scrollPx / winHeightPx;
      setScrollProgress(scrolled);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  const handleStart = (e) => {
    setIsDragging(true);
    handleMove(e);
  };

  const handleMove = (e) => {
    if (isDragging && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      const scrollPercentage = (clientY - containerRect.top) / containerRect.height;
      const scrollTarget = scrollPercentage * (document.documentElement.scrollHeight - window.innerHeight);
      window.scrollTo(0, scrollTarget);
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const moveHandler = (e) => isDragging && handleMove(e);
    const endHandler = () => isDragging && handleEnd();

    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', endHandler);
    document.addEventListener('touchmove', moveHandler);
    document.addEventListener('touchend', endHandler);

    return () => {
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', endHandler);
      document.removeEventListener('touchmove', moveHandler);
      document.removeEventListener('touchend', endHandler);
    };
  }, );

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        right: '10px',
        top: '20px',
        bottom: '20px',
        width: '4px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
    >
      <Box
        sx={{
          height: `${scrollProgress * 100}%`,
          backgroundColor: 'rgb(4, 125, 141)',
          borderRadius: '4px',
          transition: 'height 0s',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 50%)',
            width: '25px',
            height: '30px',
            backgroundColor: 'rgb(4, 125, 141)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '40% 40% 50% 50% / 40% 40% 60% 60%',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            cursor: 'grab',
            '&:active': {
              cursor: 'grabbing',
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: '0.7rem',
            }}
          >
            {Math.round(scrollProgress * 100)}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ScrollProgressIndicator;