import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ThemeContext } from '../theme';
const BackButton = ({ handleBack }) => {
    const { isDarkMode } = React.useContext(ThemeContext);
    return (
        <IconButton
            onClick={handleBack}
            sx={{
                position: 'absolute', // Fixes the button relative to its container
                left: '10px', // Places it near the left edge
                top: '10px', // Places it near the top edge
                width: '36px', // Define fixed width
                height: '50px', // Define fixed height
                color: isDarkMode? 'white':'black', // Adjust as per your theme
                // backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slight transparent background
                boxShadow: isDarkMode? '0px 2px 4px rgba(200,200, 200, 0.2)': '0px 2px 4px rgba(0,0, 0, 0.2)', // Subtle shadow for visibility
                '&:hover': {
                    backgroundColor: 'rgba(200, 200, 200, 0.8)', // Lighter hover effect
                },
                borderRadius: '8px',
                
                zIndex: 10, // Ensures it appears on top of other elements
            }}
        >
            <ArrowBack />
        </IconButton>
    );
};

export default BackButton;
