import React from 'react';
import { Card, CardActionArea, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Post({ post }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/book/${post.slug}`, { state: { post } });
    console.log(post.id)
  };

  return (
    <Box sx={{ width: 200, height: 300, position: 'relative', overflow: 'hidden' }}>
      <Card 
        sx={{ 
          width: '100%', 
          height: '100%', 
          position: 'relative',
        }}
      >
        <CardActionArea 
          sx={{ 
            width: '100%', 
            height: '100%',
          }} 
          onClick={handleClick}
        >
          <CardMedia
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            image={`data:image/jpeg;base64,${post.image_data}`}
            alt={post.title}
          />
          <Box 
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
              transition: 'opacity 0.3s',
              '&:hover': {
                opacity: 1,
              },
              padding: 2,
              overflow: 'auto',
            }}
          >
            <Typography gutterBottom variant="h6" component="div" align="center">
              {post.title}
            </Typography>
            <Typography variant="body2" align="center">
              {post.summary}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}