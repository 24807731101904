import React, { useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Post from "../components/post.js";
import { API_BASE_URL, API_ENDPOINTS } from '../api.js';
import './IndexPage.css';
import { useQuery } from 'react-query';
import FullScreenLoading from "../components/loadingScreen.js";
import BackButton from "../components/backbutton.js";

const fetchPosts = async () => {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.FETCH}`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};



export default function IndexPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const scrollContainerRefs = useRef({});

    const navigate = useNavigate();
    const { data: categories, isLoading, error } = useQuery('posts', fetchPosts, {
        select: (data) => Object.entries(data).map(([name, posts]) => ({ name, posts }))
    });

    const searchResults = searchTerm
        ? categories?.flatMap(category =>
            category.posts.filter(post =>
                post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                post.summary.toLowerCase().includes(searchTerm.toLowerCase())
            )
        )
        : [];

    const scroll = (categoryName, direction) => {
        const container = scrollContainerRefs.current[categoryName];
        if (container) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchResultClick = (post) => {
        navigate(`/book/${post.slug}`);
    };
    const handleBack = () => {
        navigate(`/`);
    }

    if (localStorage.getItem('username') === "admin") {
        return <Navigate to="/create" />;
    }

    if (isLoading) {
        return (
            // <div className="loading-container">
            //     <div className="loading-spinner"></div>
            //     <p className="loading-text">Loading amazing content...</p>
            // </div>
            <FullScreenLoading />
        );
    }

    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="categories-container" style={{ position: 'relative', paddingTop: '10px' }}>
            {/* Back Button */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <BackButton handleBack={handleBack} />

                {/* Search Section */}
                <div className="search-container">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search books..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                        <button className="search-button" onClick={() => handleSearchChange(searchTerm)}>
                            Search
                        </button>
                    </div>
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            {searchResults.map((post, index) => (
                                <div
                                    key={index}
                                    className="search-result-item"
                                    onClick={() => handleSearchResultClick(post)}
                                >
                                    {post.title}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

            </div>
            {/* Categories Section */}
            {categories.map((category) => (
                <div key={category.name} className="category">
                    <h2 className="genre-title">{category.name}</h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll(category.name, 'left')}>
                            &#8249;
                        </button>
                        <div
                            className="posts-scroll-container"
                            ref={(el) => (scrollContainerRefs.current[category.name] = el)}
                        >
                            {category.posts.map((post, index) => (
                                <Post key={index} post={post} />
                            ))}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll(category.name, 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );

}