import React, { useContext, useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import { Button, Typography, styled, Box, useMediaQuery, useTheme } from "@mui/material";
import { ThemeContext } from './theme';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from './api.js'
import AnimatedLogo from './components/logo.js';

export default function Header() {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);
    const [booksRead, setBooksRead] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isLoggedIn = !!localStorage.getItem('username');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchBooksRead = async () => {
            const username = localStorage.getItem('username');
            if (username) {
                try {
                    const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.QUEST}?username=${username}`);
                    setBooksRead(response.data.books_read);
                } catch (error) {
                    console.error('Error fetching books read:', error);
                }
            }
        };

        fetchBooksRead();
    }, []);

    const handleAuth = () => {
        if (isLoggedIn) {
            // Implement logout logic
            localStorage.removeItem('username');
            // Additional logout actions (e.g., clearing other data, redirecting)
            window.location.reload();
            navigate('/');
        } else {
            // Redirect to login page
            navigate('/login');
        }
    };
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#aab4be',
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#8796A5',
                    }),
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#001e3c',
            width: 32,
            height: 32,
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            ...theme.applyStyles('dark', {
                backgroundColor: '#003892',
            }),
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#aab4be',
            borderRadius: 20 / 2,
            ...theme.applyStyles('dark', {
                backgroundColor: '#8796A5',
            }),
        },
    }));

    // const LogoContainer = styled(Link)(({ isMobile }) => ({
    //     textDecoration: 'none',
    //     display: 'inline-flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     paddingTop: '20px', // Padding from the top
    //     marginBottom: isMobile ? '15px' : '0',
    // }));

    return (
        <Box component="header" sx={{
            maxWidth: "90%",
            margin: "0 auto",
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'center' : 'flex-start',
            padding: '10px 20px',
            color: isDarkMode ? '#fff' : '#000',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'space-between' : 'flex-start',
                width: isMobile ? '100%' : 'auto',
                marginBottom: isMobile ? '15px' : '0',
            }}>
                <AnimatedLogo />
                {isMobile && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: isMobile ? '0' : '15px',
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30px" height="30px">
                            <circle cx="50" cy="50" r="40" fill="gold" stroke="orange" strokeWidth="5" />
                            <circle cx="50" cy="50" r="35" fill="none" stroke="orange" strokeWidth="3" />
                            <circle cx="40" cy="40" r="2" fill="white" opacity="0.8" />
                            <circle cx="60" cy="40" r="3" fill="white" opacity="0.5" />
                            <circle cx="50" cy="60" r="4" fill="white" opacity="0.2" />
                            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fontSize="40px" fontFamily="Arial" fill="brown">QC</text>
                        </svg>
                        <Typography
                            variant="h6"
                            sx={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                                color: 'inherit',
                            }}
                        >
                            {booksRead}
                        </Typography>
                    </Box>
                )}
            </Box>
    
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'center' : 'center',
                justifyContent: isMobile ? 'center' : 'flex-end',
                marginTop: isMobile ? '10px' : '0',
            }}>
                {!isMobile && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '20px',
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30px" height="30px">
                            <circle cx="50" cy="50" r="40" fill="gold" stroke="orange" strokeWidth="5" />
                            <circle cx="50" cy="50" r="35" fill="none" stroke="orange" strokeWidth="3" />
                            <circle cx="40" cy="40" r="2" fill="white" opacity="0.8" />
                            <circle cx="60" cy="40" r="3" fill="white" opacity="0.5" />
                            <circle cx="50" cy="60" r="4" fill="white" opacity="0.2" />
                            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fontSize="40px" fontFamily="Arial" fill="brown">QC</text>
                        </svg>
                        <Typography
                            variant="h6"
                            sx={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                                color: 'inherit',
                            }}
                        >
                            {booksRead}
                        </Typography>
                    </Box>
                )}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: isMobile ? '0' : '20px',
                    marginBottom: isMobile ? '10px' : '0',
                }}>
                    <MaterialUISwitch
                        checked={isDarkMode}
                        onChange={toggleTheme}
                        name="darkModeSwitch"
                        className="darkToggle"
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop:'10px'
                }}>
                    {!isMobile && isLoggedIn && (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginTop: '10px',
                                marginBottom: '5px',
                                fontWeight: 'bold',
                                color: isDarkMode ? '#fff' : '#000',
                                textAlign: 'center',
                                fontSize: '0.9rem',
                                backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                padding: '3px 8px',
                                borderRadius: '40px',
                            }}
                        >
                            {localStorage.getItem('username')}
                        </Typography>
                    )}
                    <Button
                        onClick={handleAuth}
                        variant={isMobile ? "outlined" : "text"}
                        sx={{
                            color: isDarkMode ? '#fff' : '#000',
                            borderColor: isMobile ? (isDarkMode ? '#fff' : '#000') : 'transparent',
                            textTransform: 'none',
                            padding: isMobile ? '5px 15px' : '3px 8px',
                            borderRadius: isMobile ? '20px' : '4px',
                            '&:hover': {
                                backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                borderColor: isMobile ? (isDarkMode ? '#fff' : '#000') : 'transparent',
                            }
                        }}
                    >
                        {isLoggedIn ? 'Sign Out' : 'Sign In'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
