import React, { useEffect } from 'react';
import './App.css';
import Layout from './layout';
import IndexPage from './pages/indexPage';
import LoginPage from './pages/loginPage';
import RegisterPage from './pages/registerPage';
import { Route, Routes } from "react-router-dom"
import CreatePost from './pages/createPost';
import FullPost from './pages/bookPage';
import QuotesPage from './pages/quotespage';
import { ThemeProvider, ThemeContext } from './theme';
import HomePage from './pages/homepage';
import ScrollToTop from './components/scrolltop';
import AboutPage from './pages/about';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { API_BASE_URL, API_ENDPOINTS } from './api';
import { useGoogleAnalytics } from './useGoogleAnalytics';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Cache data for 30 minutes
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister,
});

// Function to fetch all posts
const fetchPosts = async () => {
  const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.FETCH}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

function App() {
  useGoogleAnalytics();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

function AppContent() {
  const { isDarkMode } = React.useContext(ThemeContext);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  // Prefetch all posts when the app loads
  useEffect(() => {
    queryClient.prefetchQuery('allPosts', fetchPosts);
  }, [queryClient]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/register'} element={<RegisterPage />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path={'/library'} element={<IndexPage />} />
          <Route path="/book/:bookId" element={<FullPost />} />
          <Route path="/quotes" element={<QuotesPage/>} />
          <Route path="/about" element={<AboutPage />} />
        </Route>
        <Route path={'/create'} element={<CreatePost />} />
      </Routes>
    </>
  );
}

export default App;