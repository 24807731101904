import Header from "./header"
import "./App.css"
import Footer from "./pages/footerPage"
import { Outlet } from "react-router-dom"
export default function Layout() {
    return (
        <div >

                <Header />
            <main>
                <div className="curved-line"></div>

                <Outlet />
                <Footer />
            </main>
        </div>
    )
}