import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logoLite from '../images/trace.svg';   // SVG for light mode
import logoDark from '../images/trace1.svg';  // SVG for dark mode
import { ThemeContext } from '../theme';      // Import ThemeContext

// Logo Container with Padding and Responsiveness
const LogoContainer = styled(Link)(({ isMobile }) => ({
  textDecoration: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '20px', // Padding from the top
  marginBottom: isMobile ? '15px' : '0',
}));

// Optional: You can set width and height of the SVG if necessary
const StyledSVG = styled('img')(({ isMobile }) => ({
  width: isMobile ? '150px' : '250px', // Set width based on device size
  height: 'auto', // Maintain aspect ratio
}));

const AnimatedLogo = ({ isMobile }) => {
  // Access the current theme from ThemeContext
  const { isDarkMode } = useContext(ThemeContext);

  // Choose the appropriate SVG based on the theme
  const logoSrc = isDarkMode ? logoDark : logoLite;

  return (
    <LogoContainer to="/" isMobile={isMobile}>
      <StyledSVG src={logoSrc} alt="ReadQuest Logo" isMobile={isMobile} />
    </LogoContainer>
  );
};

export default AnimatedLogo;
